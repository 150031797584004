.mainContainer {
  height: 100vh;
  z-index: 1;
  flex-grow: 1;
  background: rgba(34, 34, 34, 0.6);
  backdrop-filter: blur(10px);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.25, 1);
}

.banner {
  height: 300px;
  position: relative;
}

.banner .bannerImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
}

.banner .bannerContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.banner .bottomLayer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
}

.banner .bannerContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  z-index: 3;
}

.banner .bannerContent .breadCrump {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner .bannerContent .breadCrump p {
  color: #848484;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
}

.banner .bannerContent .breadCrump p span {
  color: #f1f1f1;
}

.banner .bannerContent i {
  color: #f1f1f1;
  font-size: 24px;
  cursor: pointer;
}

.banner .bannerContent .artist {
  margin-top: auto;
  color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner .bannerContent .artist .left .name {
  display: flex;
  align-items: center;
}

.banner .bannerContent .artist .left .name h2 {
  font-size: 50px;
}

.banner .bannerContent .artist .left .name img {
  width: 30px;
  margin-left: 10px;
  position: relative;
  top: 8px;
}

.banner .bannerContent .artist .left p {
  font-size: 14px;
}
.banner .bannerContent .artist .left p i {
  margin-right: 5px;
}

.banner .bannerContent .artist .left p span {
  color: #848484;
  margin-left: 5px;
}

.banner .bannerContent .artist .right {
  display: flex;
  align-items: center;
}

.banner .bannerContent .artist .right a {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px 30px;
  font-size: 16px;
  color: #f1f1f1;
  margin: 0 15px;
  border-radius: 100vh;
  font-weight: bold;
  text-align: center;
}

.banner .bannerContent .artist .right a i {
  margin-right: 10px;
  font-size: 12px;
}

.banner .bannerContent .artist .right a:nth-child(1) {
  background: #39aa24;
  margin-left: 0;
}

.banner .bannerContent .artist .right a:nth-child(2) {
  background: rgba(0, 0, 0, 0.4);
  margin-right: 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.banner .bannerContent .artist .right a:hover {
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.4);
}

.mainContainer .menuList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  background: #2c2c2c;
}

.mainContainer .menuList ul {
  display: flex;
  align-items: center;
}

.mainContainer .menuList ul li::before {
  position: absolute;
  content: "";
  bottom: -20px;
  left: 10px;
  width: 0px;
  height: 5px;
  border-radius: 5px;
  background: #f1f1f1;
  align-items: center;
  justify-content: space-between;
}

.mainContainer .menuList ul li {
  list-style: none;
  margin: 0 20px;
  position: relative;
}

.mainContainer .menuList ul li:hover::before,
.mainContainer .menuList ul li.active:before {
  width: 25px;
  transition: width 0.3s ease;
}

.mainContainer .menuList ul li a {
  text-decoration: none;
  color: #848484;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1);
}

.mainContainer .menuList ul li:hover a,
.mainContainer .menuList ul li.active a {
  color: #f1f1f1;
  transition: 0.3s;
}

.mainContainer .menuList p {
  color: #f1f1f1;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
}

.mainContainer .menuList p i {
  margin-right: 5px;
  font-size: 16px;
  top: 3px;
  position: relative;
  margin-left: 3px;
}

.mainContainer .menuList p span {
  color: #848484;
  font-size: 12px;
  text-align: center;
  margin-left: 5px;
}

.audioList {
  padding: 10px 30px;
}

.audioList .title {
  font-size: 18px;
  color: #f1f1f1;
}

.audioList .title span {
  color: #848484;
  font-size: 12px;
  margin-left: 5px;
}

.songsContainer {
  height: 220px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.songs {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #848484;
  width: 100%;
  margin: 10px 0;
  padding: 5px;
}

.songs:hover,
.songs.active {
  color: #f1f1f1;
}

.count {
  margin-right: 10px;
}
.song {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 5px;
  cursor: pointer;
}

.song .imgBox {
  width: 45px;
  height: 45px;
  min-width: 45px;
  border-radius: 0.5rem;
  background: #555;
  overflow: hidden;
  margin-right: 10px;
}

.song .imgBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.song .section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.song .section .songName {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}

.song .section .songName .spanArtist {
  align-items: center;
}

.song .section .hits {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.song .section .hits .hit,
.song .section .hits .duration {
  margin-right: 15px;
}

.song .section .hits .hit i,
.song .section .hits .duration i {
  font-size: 12px;
  margin-right: 5px;
}

.song .section .hits .favorite i {
  color: #39aa24;
  filter: drop-shadow(0px 0px 5px #39aa24);
}

/*Music Player*/

.musicPlayer {
  width: 100%;
  display: flex;
  padding: 10px;
}

.musicPlayer .songImg {
  width: 120px;
  min-height: 120px;
  height: 80px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0, 0, 20px rgba(0, 0, 0, 0.5);
}

.musicPlayer .songImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.musicPlayer .songAttr {
  width: 100%;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.musicPlayer .songAttr .top,
.musicPlayer .songAttr .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.musicPlayer .songAttr .top .left,
.musicPlayer .songAttr .top .middle,
.musicPlayer .songAttr .top .right {
  color: #595959;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.musicPlayer .songAttr .top .left i,
.musicPlayer .songAttr .top .middle i,
.musicPlayer .songAttr .top .right i {
  margin: 0px 15px;
  font-size: 18px;
}

.musicPlayer .songAttr .top .left .loved i {
  color: #39aa24;
  filter: drop-shadow(0px 0px 5px #39aa24);
}

.musicPlayer .songAttr .top .middle .back i:nth-child(2),
.musicPlayer .songAttr .top .middle .forward i:nth-child(1) {
  color: #9a9a9a !important;
}

.musicPlayer .songAttr .top .middle .playPause {
  color: #f1f1f1;
  filter: drop-shadow(0px 0px 5px #f1f1f1);
}

.musicPlayer .songAttr .bottom {
  margin-top: 20px;
  padding: 0px 15px;
}

.musicPlayer .songAttr .bottom .progressBar {
  width: 80%;
  position: relative;
  height: 5px;
  outline: none;
  border: none;
  appearance: none;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  overflow: hidden;
}

.musicPlayer .songAttr .bottom .progressBar:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background: #848484;
  width: var(--player-played);
  height: 100%;
  border-radius: 10px;
  z-index: 2;
  transition: all 0.5s ease;
}

.musicPlayer .songAttr .bottom .progressBar::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: none;
}

.musicPlayer .songAttr .bottom .progressBar::-moz-range-track {
  width: 80%;
  position: relative;
  height: 5px;
  outline: none;
  border: none;
  appearance: none;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.musicPlayer .songAttr .bottom .progressBar::-moz-range-progress {
  background: #848484;
  width: var(--player-played);
  height: 100%;
  border-radius: 10px;
  z-index: 2;
  transition: all 0.5s ease;
}

.musicPlayer .songAttr .bottom .progressBar::-moz-range-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: none;
}

.musicPlayer .songAttr .bottom .currentTime,
.musicPlayer .songAttr .bottom .totalTime {
  color: #595959;
  font-size: 14px;
  font-weight: bold;
}

@media screen and (max-width: 550px) {
  .mainContainer {
    min-width: 380px;
  }

  .content {
    padding: 10px 15px;
  }

  .banner .bannerContent .breadCrump P {
    font-size: 12px;
  }

  .banner .bannerContent .breadCrump i {
    font-size: 14px;
  }

  .banner .bannerContent .artist .left .name h2 {
    font-size: 25px;
  }

  .banner .bannerContent .artist .right a {
    padding: 5px 15px;
    font-size: 12px;
    margin: 0 10px;
  }

  .mainContainer .menuList ul li {
    margin: 0 5px;
  }

  .mainContainer .menuList ul li a {
    font-size: 14px;
  }

  .mainContainer .menuList p {
    font-size: 12px;
  }

  .mainContainer .menuList p i {
    font-size: 10px;
  }

  .mainContainer .menuList p span {
    margin-left: 0px;
    font-size: 10px;
    text-align: center;
    display: block;
  }

  .mainContainer .menuList {
    padding: 10px 15px;
  }

  .audioList {
    padding: 10px 5px;
  }

  .audioList .songsContainer .songs .song .section {
    flex-direction: column;
  }

  .audioList .songsContainer .songs .song .section .songName {
    width: 100%;
  }

  .audioList .songsContainer .songs .song .section .hits {
    margin-left: 0;
    margin-top: 5px;
    justify-content: space-between;
  }
}

@media screen and (max-width: 950px) {
  .Banner {
    height: 240px !important;
    position: relative;
  }
}
