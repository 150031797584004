.rightContainer {
  height: 100vh;
  width: 100px;
  z-index: 1;
  background: rgba(34, 34, 34, 0.6);
  backdrop-filter: blur(10px);
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px;
}

.rightContainer .goPro,
.rightContainer .profile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.rightContainer .goPro i,
.rightContainer .profile i {
  color: #f1f1f1;
  margin: 20px 0;
  text-align: center;
  font-size: 20px;
  position: relative;
}

.rightContainer .goPro i:hover .rightContainer .profile i:hover {
  transform: scale(1.2);
  transition: transform 0.3s;
}

.rightContainer .goPro i p {
  font-size: 14px;
}

.rightContainer .goPro i:nth-child(2)::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 0;
  right: 0;
  background: #45ab2e;
}

.rightContainer .goPro i p span {
  color: #848484;
  margin-right: 5px;
}

.rightContainer .profile .profileImage {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 100%;
  box-shadow: 0px 0px 20px rgba(34, 34, 34, 0.6);
}

.rightContainer .profile .profileImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 550px) {
  .rightContainer {
    min-width: 64px;
    width: 64px;
  }
}
