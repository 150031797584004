.leftMenu {
  z-index: 1;
  width: 280px;
  height: 100vh;
  min-height: 100vh;
  background: rgba(34, 34, 34, 0.6);
  backdrop-filter: blur(10px);
  padding: 20px 15px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.leftMenu .logoContainer {
  color: #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftMenu .logoContainer i {
  font-size: 30px;
}

.leftMenu .logoContainer i:nth-child(3) {
  font-size: 20px !important;
}

.leftMenu .logoContainer h2 {
  font-size: 20px;
}

.searchBox {
  width: 100%;
  height: 45px;
  position: relative;
  margin-top: 20px;
  color: #848484;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
}

.searchBox input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  background: rgba(0, 0, 0, 0.5);
  padding-left: 45px;
  color: #f1f1f1;
  font-size: 14px;
  font-weight: bold;
}
.searchBox .searchIcon {
  position: absolute;
  color: #848484;
  top: 0;
  left: 0;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  font-size: 20px;
}

.MenuContainer {
  margin: 15px 0;
}

.MenuContainer .title {
  color: #848484;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.MenuContainer ul {
  width: 100%;
  padding: 0;
}

.MenuContainer ul li::before {
  position: absolute;
  content: "";
  top: 0;
  left: -15px;
  width: 0;
  height: 100%;
  background: #49e12e;
  border-radius: 5px;
}

.MenuContainer ul li {
  list-style: none;
  margin: 5px 0;
  width: 100%;
  padding: 5px 0;
  position: relative;
}

.MenuContainer ul li:hover::before,
.MenuContainer ul li.active::before {
  width: 5px;
  transition: all 0.3s ease;
}

.MenuContainer ul li a {
  text-decoration: none;
  color: #848484;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.MenuContainer ul li:hover a,
.MenuContainer ul li.active a {
  color: #f1f1f1;
  transition: all 0.3s ease;
}

.MenuContainer ul li a i {
  font-size: 18px;
  margin-right: 15px;
}

.playListContainer {
  cursor: pointer;
  margin: 15px 0;
}

.playListContainer .playListTitle {
  color: #848484;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.playListContainer .playListTitle p {
  font-size: 12px;
  font-weight: bold;
  color: #848484;
  text-transform: uppercase;
}

.playListContainer .playListTitle i {
  cursor: pointer;
  color: #f1f1f1;
}

.playListContainer .playListContent {
  height: 160px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

.playListContainer .playListContent .playList {
  display: flex;
  color: #848484;
  align-items: center;
  margin: 10px 0;
}

.playListContainer .playListContent .playList:hover {
  color: #f1f1f1;
  transition: all 0.3s ease;
}

.playListContainer .playListContent .playList i {
  font-size: 18px;
}
.playListContainer .playListContent .playList .list {
  margin-right: 15px;
}

.playListContainer .playListContent .playList .trash {
  margin-left: auto;
}

.playListContainer .playListContent .playList p {
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline;
}

.trackList {
  border-top: 1px solid #f1f1f1;
  padding-top: 20px;
}

.trackList .trackListHeader {
  display: flex;
  align-items: center;
}

.trackList .trackListHeader img {
  width: 50px !important;
}

.trackList .trackListHeader p {
  margin-left: 10px;
  font-size: 14px;
  color: #f1f1f1;
}

.trackList .trackListHeader p span {
  display: block;
  font-size: 12px;
  color: #848484;
}

.trackList .trackListContent {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
  color: #848484;
}

.trackList .trackListContent i {
  font-size: 18px;
}

.trackList .trackListContent i:hover {
  color: #f1f1f1;
  transition: all 0.3s ease;
  cursor: pointer;
}

.trackList .trackListContent input {
  position: relative;
  height: 5px;
  border: none;
  outline: none;
  border-radius: 10px;
  background: #848484;
  appearance: none;
}

/*Chrome, Safari*/

.trackList .trackListContent input::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: #49e12e;
  border-radius: 10px;
  z-index: 2;
  transition: width 250ms linear;
}

.trackList .trackListContent input::-webkit-slider-thumb {
  appearance: none;
  width: 10px;
  height: 20px;
  border-radius: 10px;
  background: #f1f1f1;
  cursor: pointer;
  position: relative;
  margin: -2px 0 0 0;
  border: 2px solid #000;
  z-index: 3;
  box-sizing: border-box;
  transition: all 250ms linear;
}

/*Firefox*/

.trackList .trackListContent input::-moz-progress-bar {
  width: 50%;
  height: 100%;
  background: #49e12e;
  border-radius: 10px;
  z-index: 2;
  transition: width 250ms linear;
}

.trackList .trackListContent input::-moz-range-thumb {
  width: 50%;
  height: 100%;
  background: #49e12e;
  border-radius: 10px;
  z-index: 2;
  transition: width 250ms linear;
}

@media screen and (max-width: 550px) {
  .leftMenu {
    width: 64px;
    min-width: 64px;
  }

  .leftMenu .searchBox {
    display: none;
  }

  .leftMenu .trackList {
    display: none;
  }

  .leftMenu .playListContainer {
    display: none;
  }
  .leftMenu .logoContainer h2 {
    display: none;
  }
  .leftMenu .logoContainer :nth-child(3) {
    display: none;
  }

  .leftMenu .MenuContainer ul li a span {
    display: none;
  }
  .leftMenu .MenuContainer ul li a i {
    align-items: center;
    left: 5px;
    position: relative;
    font-size: 22px;
  }
  .MenuContainer .title {
    display: none;
  }
}
